<template>
  <v-app>

    <v-system-bar
        app
        light
        color="amber lighten-2"
        style="white-space:nowrap; text-overflow: ellipsis; overflow: hidden"

    >
      {{ $store.state.qr_event_data.show }} {{ $store.state.qr_event_data.date }}
      <v-spacer></v-spacer>

<!--   список продавцов-->
      <v-icon v-if="$store.state.mongerList.b">mdi-account</v-icon>
      <v-icon v-else class="red--text">mdi-account-off</v-icon>

<!--      загрузили спектакль и его реализованные билеты с qr-->
      <v-icon :class="{'red--text':!$store.state.qr_event_data.b}">mdi-ticket</v-icon>


<!--      <v-icon>mdi-signal-cellular-outline</v-icon>-->
<!--      <v-icon>mdi-pencil</v-icon>-->


    </v-system-bar>

    <v-app-bar
        app
        color="amber lighten-1"
        light
        dense
    >
      <v-app-bar-nav-icon color="brown" @click="drawer = !drawer"></v-app-bar-nav-icon>


      <div class="d-flex align-center">
        ПроходЪ
      </div>

      <v-spacer></v-spacer>

      САМАРТ

    </v-app-bar>

    <v-navigation-drawer
        app
        v-model="drawer"
        color="lime lighten-4"
    >



      <v-list>

        <v-list-item class="pa-4 amber lighten-3" >
          <v-img
              src="https://zakaz.cloud/head.png"
              aspect-ratio="1.7"
              height="105"
              contain
          ></v-img>
        </v-list-item>

        <br>






        <v-list-item-group
            v-model="menu_selected"
            active-class="orange--text"
        >

          <router-link
              v-for="[icon, text, color, r_link, need_div] in links_scanner"
              :to="r_link"
              v-slot="{ href, route, navigate, isActive, isExactActive}"
              :key="icon"
          >
            <v-list-item :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']" @click="navigate">
              <v-list-item-action>
                <v-icon :color='color'>{{ icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

<!--            <v-divider v-if="need_div" ></v-divider>-->



          </router-link>

        </v-list-item-group>

      </v-list>


    </v-navigation-drawer>




    <v-main class="amber lighten-5" style="word-wrap: break-word">
      <router-view/>
    </v-main>

    <v-footer app padless class="amber lighten-4">

      <v-col
          class="text-center pa-0"
          cols="12"

      >



          <v-chip
              class="ma-0"
              small
              color="red"
              text-color="white"

          >
            <v-avatar>
<!--              <v-icon small>mdi-wrench</v-icon>-->
              <v-icon small>mdi-calendar-today</v-icon>
            </v-avatar>

          </v-chip>



          <v-chip
              class="ma-2"
              small
              color="green"
              text-color="white"
          >
            <v-avatar left>
              <v-icon small>mdi-barcode-scan</v-icon>
            </v-avatar>
            {{ $store.state.current_scan_info.txt_scan}}
          </v-chip>

        <v-chip
            class="ma-0"
            small
            color="blue"
            text-color="white"
        >
          <v-avatar>
            <v-icon small>mdi-chart-bar</v-icon>
          </v-avatar>

        </v-chip>
      </v-col>

    </v-footer>



  </v-app>
</template>

<script>


//import API_connector from '@/core/API_connector.js'

import router from "@/router";

export default {
  name: 'App',

  components: {

  },

  data: () => ({

    //api:new API_connector(),
    //txt_typing: '',
    //txt_scan_infoline: '',

    drawer: false,
    menu_selected:0,

    links_scanner: [
      ['mdi-calendar-today',                'Сегодня',            'blue darken-3',          '/active',    true],
      ['mdi-data-matrix-scan',              'Сканировать',        'green',                  '/scan',      false],
      ['mdi-chart-bar',                     'Статистика',         'red darken-1',           '/stat',      true],
      ['mdi-ticket-confirmation-outline',   'ЗаказЪ',             'amber darken-3',         '/about',     true],

    ],

    links_kassa: [
      ['mdi-heart',                         'Главная',            'red',                    '/',          true],

      ['mdi-ticket-confirmation-outline',   'ЗаказЪ',             'amber darken-3',         '/about',     false],
    ],

    links_test: [
      ['mdi-heart',                         'Главная',            'red',                    '/',          true],
      ['mdi-calendar-today',                'Сегодня',            'blue darken-3',          '/active',    true],
      ['mdi-data-matrix-scan',              'Сканировать',        'green',                  '/scan',      false],
      ['mdi-qrcode',                        'Распознать',         'green',                  '/camera',    false],
      ['mdi-periodic-table',                'Обзор зала',         'blue',                   '/odeum',     false],
      ['mdi-chart-bar',                     'Статистика',         'red darken-1',           '/stat',      true],
      ['mdi-play',                          'Начало работы',      'purple darken-1',        '/begin',     true],
      ['mdi-square-edit-outline',           'Заметки',            'blue-grey lighten-3',    '/notes',     false],
      ['mdi-cellphone-wireless',            'Связь',              'blue-grey lighten-3',    '/messages',  true],
      ['mdi-ticket-confirmation-outline',   'ЗаказЪ',             'amber darken-3',         '/about',     true],

      ['mdi-receipt',                       'Продажа',            'red darken-1',           '/sell',        false],
      ['mdi-flag-outline',                  'Отчет',              'green darken-1',         '/report',      true],
      ['mdi-account-details',               'Операции',           'black darken-3',         '/operations',  false],
      ['mdi-human-greeting',                'Заказы',             'blue darken-3',          '/order',       false],
      ['mdi-format-rotate-90',              'Возврат',            'pink lighten-1',         '/refund',      false],


    ],
    //
  }),

  created() {
    //console.log('APP has been created!');
    //this.$store.dispatch('loadGlobal');
    // let y = this.api.loadEvent_Kassa(423);
    //
    // console.log(y);

    window.addEventListener('keydown', this.onScan, { passive: true } )
    //window.addEventListener('beforeunload', this.before)
    //console.log('APP created end');
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onScan)
    //window.removeEventListener('beforeunload', this.before)
  },

  mounted(){
    // грузим на страницу репертуара - список активных мероприятий
    this.$store.commit('loadAfishaScan');

    // грузим продавцов
    this.$store.commit('loadMongerList');

    // грузим сразу маугли 1 - 17 в 17-00
    this.$store.commit('loadEventScan', 1);


  },

  methods: {

    before(e){
      console.log('199976878678678')
      e.preventDefault()
      e.returnValue = ''

    },


    // слишком медленно обрабатывает, если его глобально установить
    onScan(e){

      // спец кнопка-курок, которая никак не отствечивает =(
      if ((e.key=="Unidentified")&&(e.keyCode==0)){
        if (this.$router.currentRoute.name!=='scan'){
          router.push('scan');
        }
      }




      //console.log(e);

      // if (e.code<1){
      //   return
      // }
      //
      // if (e.key==="Shift"){
      //   return
      // }
      //
      // if (e.code==="Space"){
      //   return
      // }
      //
      //
      // // enter
      // if (e.key==="Enter"){
      //   this.i=0;
      //   this.txt_scan_infoline = this.txt_typing;
      //   this.txt_typing = "";
      //
      //   // прописать условия
      //   // если загружена база, то ищем в ней
      //   //this.$store.commit('doScan_infoline', this.txt_scan_infoline);
      //
      //   // если нет базы - посылаем прямой запрос на север
      //
      //   // если и сети нет - ругаемся =(
      //
      //   if (this.$router.currentRoute.name!=='Scan'){
      //     router.push('Scan');
      //   }


      // }
      // else{
      //   this.txt_typing += e.key;
      // }

    }

  }

};
</script>

<style>

html, body{
  overscroll-behavior-y:contain;
}

#app{
  overscroll-behavior:none;
}

</style>
